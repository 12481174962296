.elementor-contact
{
    padding: 20px 0 0 0;
}
.elementor-section {
    position: relative;
    padding: 15px 0 15px 0;
    
}
.elementor-section-maps
{
    position: relative;
    padding: 0 0 0 0;
   
} 
.elementor-widget-wrap {
    display: block;
    align-content: center;
    align-items: center;
}
.contact-info.box-style1 {
    padding: 30px 30px 25px;
}
.box-style1 img, .box-style1 i, .box-style1 svg {
    float: left;
}

.contact-info i, .contact-info svg {
    font-size: 30px;
    margin-top: 2px;
    line-height: 1;
    color: #43baff;
}
.contact-info .info-text {
    padding-left: 50px;
    font-weight: 500;
}
.contact-info h6 {
    font-size: 16px;
    margin-bottom: 5px;
}

._main-form {
    padding: 60px 48px;
    background-image: linear-gradient(90deg,#00deff 0%,#7141b1 100%);
    color: #fff;
}
.main-form {
    padding: 0px 48px;
}
.main-form input,  .main-form textarea {
    width: 100%;
    background: rgba(255,255,255,.3);
    color: #fff;
}

.main-form button:hover {
    background: #7141b1;
}
.main-form button {
    background: #fff;
    color: black;
}