.adminwrapper {
    min-height: 100vh;
    position: relative; 
}


/*           Animations              */


.sidebar,
body > .navbar-collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20%;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center; 
}



  .sidebar .sidebar-wrapper,
  body > .navbar-collapse .sidebar-wrapper {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 100%;
    z-index: 4;
    padding-bottom: 100px; }
  .sidebar .sidebar-background,
  body > .navbar-collapse .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center; }
  .sidebar .logo,
  body > .navbar-collapse .logo {
    padding: 10px 15px 9px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 4; }
    .sidebar .logo p,
    body > .navbar-collapse .logo p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: #FFFFFF;
      line-height: 20px;
     /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;  */
    }
    .sidebar .logo .simple-text,
    body > .navbar-collapse .logo .simple-text {
      text-transform: uppercase;
      padding: 5px 0px;
      display: block;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      font-weight: 400;
      line-height: 30px; }
  .sidebar .logo-tim,
  body > .navbar-collapse .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden; }
    .sidebar .logo-tim img,
    body > .navbar-collapse .logo-tim img {
      width: 60px;
      height: 60px; }
  .sidebar .nav,
  body > .navbar-collapse .nav {
    margin-top: 20px;
    float: none;
    display: block; }
    .sidebar .nav li .nav-link,
    body > .navbar-collapse .nav li .nav-link {
      color: #FFFFFF;
      margin: 5px 15px;
      opacity: .86;
      border-radius: 4px;
      display: block;
      padding: 10px 15px; }
      .sidebar .nav li .nav-link:hover,
      body > .navbar-collapse .nav li .nav-link:hover {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1; }
      .sidebar .nav li .nav-link p,
      body > .navbar-collapse .nav li .nav-link p {
        margin: 0;
        line-height: 31px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-flex; }
      .sidebar .nav li .nav-link i,
      body > .navbar-collapse .nav li .nav-link i {
        font-size: 28px;
        margin-right: 15px;
        width: 30px;
        text-align: center;
        vertical-align: middle;
        float: left; }
    .sidebar .nav li:hover .nav-link,
    body > .navbar-collapse .nav li:hover .nav-link {
      background: rgba(255, 255, 255, 0.13);
      opacity: 1; }
    .sidebar .nav li.active .nav-link,
    body > .navbar-collapse .nav li.active .nav-link {
      color: #FFFFFF;
      opacity: 1;
      background: rgba(255, 255, 255, 0.23); }
    .sidebar .nav li.separator,
    body > .navbar-collapse .nav li.separator {
      margin: 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
      .sidebar .nav li.separator + .nav-item,
      body > .navbar-collapse .nav li.separator + .nav-item {
        margin-top: 31px; }
    .sidebar .nav .caret,
    body > .navbar-collapse .nav .caret {
      margin-top: 13px;
      position: absolute;
      right: 30px; }
    .sidebar .nav .active-pro,
    body > .navbar-collapse .nav .active-pro {
      position: absolute;
      width: 100%;
      bottom: 10px; }
      .sidebar .nav .active-pro a,
      body > .navbar-collapse .nav .active-pro a {
        color: #FFFFFF !important; }
    .sidebar .nav .nav-link,
    body > .navbar-collapse .nav .nav-link {
      color: #FFFFFF;
      margin: 5px 15px;
      opacity: .86;
      border-radius: 4px;
      text-transform: uppercase;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600; }
  .sidebar .logo,
  body > .navbar-collapse .logo {
    padding: 10px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .sidebar .logo p,
    body > .navbar-collapse .logo p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: #FFFFFF;
      line-height: 20px;
     /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/ }
    .sidebar .logo .simple-text,
    body > .navbar-collapse .logo .simple-text {
      text-transform: uppercase;
      padding: 5px 0px;
      display: block;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      font-weight: 400;
      line-height: 30px; }
  .sidebar .logo-tim,
  body > .navbar-collapse .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden; }
    .sidebar .logo-tim img,
    body > .navbar-collapse .logo-tim img {
      width: 60px;
      height: 60px; }
  .sidebar:after, .sidebar:before,
  body > .navbar-collapse:after,
  body > .navbar-collapse:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
  .sidebar:before,
  body > .navbar-collapse:before {
    opacity: .33;
    background: #000000; }
  .sidebar:after,
  body > .navbar-collapse:after {
    background: #9368E9;
    background: -moz-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9368E9), color-stop(100%, #943bea));
    background: -webkit-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -o-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -ms-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
    background-size: 150% 150%;
    z-index: 3;
    opacity: 1; }
  .sidebar[data-image]:after, .sidebar.has-image:after,
  body > .navbar-collapse[data-image]:after,
  body > .navbar-collapse.has-image:after {
    opacity: .77; }
  .sidebar[data-color="black"]:after,
  body > .navbar-collapse[data-color="black"]:after {
    background: #777777;
    background: -moz-linear-gradient(top, #777777 0%, #777777 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #777777), color-stop(100%, #777777));
    background: -webkit-linear-gradient(top, #777777 0%, #777777 100%);
    background: -o-linear-gradient(top, #777777 0%, #777777 100%);
    background: -ms-linear-gradient(top, #777777 0%, #777777 100%);
    background: linear-gradient(to bottom, #777777 0%, #777777 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="blue"]:after,
  body > .navbar-collapse[data-color="blue"]:after {
    background: #1F77D0;
    background: -moz-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1F77D0), color-stop(100%, #533ce1));
    background: -webkit-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
    background: -o-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
    background: -ms-linear-gradient(top, #1F77D0 0%, #533ce1 100%);
    background: linear-gradient(to bottom, #1F77D0 0%, #533ce1 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="azure"]:after,
  body > .navbar-collapse[data-color="azure"]:after {
    background: #1DC7EA;
    background: -moz-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1DC7EA), color-stop(100%, #4091ff));
    background: -webkit-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
    background: -o-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
    background: -ms-linear-gradient(top, #1DC7EA 0%, #4091ff 100%);
    background: linear-gradient(to bottom, #1DC7EA 0%, #4091ff 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="green"]:after,
  body > .navbar-collapse[data-color="green"]:after {
    background: #87CB16;
    background: -moz-linear-gradient(top, #87CB16 0%, #6dc030 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #87CB16), color-stop(100%, #6dc030));
    background: -webkit-linear-gradient(top, #87CB16 0%, #6dc030 100%);
    background: -o-linear-gradient(top, #87CB16 0%, #6dc030 100%);
    background: -ms-linear-gradient(top, #87CB16 0%, #6dc030 100%);
    background: linear-gradient(to bottom, #87CB16 0%, #6dc030 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="orange"]:after,
  body > .navbar-collapse[data-color="orange"]:after {
    background: #FFA534;
    background: -moz-linear-gradient(top, #FFA534 0%, #ff5221 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFA534), color-stop(100%, #ff5221));
    background: -webkit-linear-gradient(top, #FFA534 0%, #ff5221 100%);
    background: -o-linear-gradient(top, #FFA534 0%, #ff5221 100%);
    background: -ms-linear-gradient(top, #FFA534 0%, #ff5221 100%);
    background: linear-gradient(to bottom, #FFA534 0%, #ff5221 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="red"]:after,
  body > .navbar-collapse[data-color="red"]:after {
    background: #FB404B;
    background: -moz-linear-gradient(top, #FB404B 0%, #bb0502 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FB404B), color-stop(100%, #bb0502));
    background: -webkit-linear-gradient(top, #FB404B 0%, #bb0502 100%);
    background: -o-linear-gradient(top, #FB404B 0%, #bb0502 100%);
    background: -ms-linear-gradient(top, #FB404B 0%, #bb0502 100%);
    background: linear-gradient(to bottom, #FB404B 0%, #bb0502 100%);
    background-size: 150% 150%; }
  .sidebar[data-color="purple"]:after,
  body > .navbar-collapse[data-color="purple"]:after {
    background: #9368E9;
    background: -moz-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9368E9), color-stop(100%, #943bea));
    background: -webkit-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -o-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: -ms-linear-gradient(top, #9368E9 0%, #943bea 100%);
    background: linear-gradient(to bottom, #9368E9 0%, #943bea 100%);
    background-size: 150% 150%; }

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 20%);
  min-height: 100%; }
  .main-panel > .content {
   
    min-height: calc(100% - 123px); }
  .main-panel > .footer {
    border-top: 1px solid #e7e7e7; }
  .main-panel .navbar {
    margin-bottom: 0; }


@media only screen and (max-width: 600px) {

      .sidebar,
    body > .navbar-collapse {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      z-index: 1;
      color: #fff;
      font-weight: 200;
      background-size: cover;
      background-position: center center; 
    }

    .main-panel {
      background: rgba(203, 203, 210, 0.15);
      position: relative;
      float: right;
      width: 100%;
      min-height: 100%; 
    }
}

.sidebar,
.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top,bottom;
  transition-property: top,bottom;
  -webkit-transition-duration: .2s,.2s;
  transition-duration: .2s,.2s;
  -webkit-transition-timing-function: linear,linear;
  transition-timing-function: linear,linear;
  -webkit-overflow-scrolling: touch; }

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translate3d(0, -5%, 0) !important;
  -moz-transform: translate3d(0, -5%, 0) !important;
  -o-transform: translate3d(0, -5%, 0) !important;
  -ms-transform: translate3d(0, -5%, 0) !important;
  transform: translate3d(0, -5%, 0) !important;
  border-radius: 10px; }
  .fixed-plugin .dropdown .dropdown-menu li.adjustments-line {
    border-bottom: 1px solid #ddd; }
  .fixed-plugin .dropdown .dropdown-menu li {
    padding: 5px 2px !important; }
  .fixed-plugin .dropdown .dropdown-menu .button-container a {
    font-size: 14px; }
  .fixed-plugin .dropdown .dropdown-menu .button-container.show {
    -webkit-transform: translate3d(0, 0%, 0) !important;
    -moz-transform: translate3d(0, 0%, 0) !important;
    -o-transform: translate3d(0, 0%, 0) !important;
    -ms-transform: translate3d(0, 0%, 0) !important;
    transform: translate3d(0, 0%, 0) !important;
    transform-origin: 0 0;
    left: -303px !important; }

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translate3d(0, -5%, 0) !important;
  -moz-transform: translate3d(0, -5%, 0) !important;
  -o-transform: translate3d(0, -5%, 0) !important;
  -ms-transform: translate3d(0, -5%, 0) !important;
  transform: translate3d(0, -5%, 0) !important;
  top: -40px !important;
  opacity: 0;
  left: -303px !important;
  transform-origin: 0 0; }

.fixed-plugin .dropdown.show .dropdown-menu {
  opacity: 1;
  -webkit-transform: translate3d(0, 0%, 0) !important;
  -moz-transform: translate3d(0, 0%, 0) !important;
  -o-transform: translate3d(0, 0%, 0) !important;
  -ms-transform: translate3d(0, 0%, 0) !important;
  transform: translate3d(0, 0%, 0) !important;
  transform-origin: 0 0;
  left: -303px !important; }

.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 65px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%); }

.fixed-plugin .dropdown-menu:before {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid rgba(0, 0, 0, 0.2);
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -16px; }

.fixed-plugin .dropdown-menu:after {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid #fff;
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -15px; }



.nav .nav-item .nav-link:hover,
.nav .nav-item .nav-link:focus {
  background-color: transparent; }



.navbar-transparent .navbar-brand, [class*="navbar-ct"] .navbar-brand {
  color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }
  .navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="navbar-ct"] .navbar-brand:focus, [class*="navbar-ct"] .navbar-brand:hover {
    background-color: transparent;
    opacity: 1;
    filter: alpha(opacity=100);
    color: #FFFFFF; }

.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="navbar-ct"] .navbar-nav .nav-item .nav-link:not(.btn) {
  color: #FFFFFF;
  border-color: #FFFFFF;
  opacity: 0.8;
  filter: alpha(opacity=80); }

.navbar-transparent .navbar-nav .active .nav-link:not(.btn),
.navbar-transparent .navbar-nav .active .nav-link:hover:not(.btn),
.navbar-transparent .navbar-nav .active .nav-link:focus:not(.btn),
.navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn),
.navbar-transparent .navbar-nav .nav-item .nav-link:hover:not(.btn),
.navbar-transparent .navbar-nav .nav-item .nav-link:focus:not(.btn), [class*="navbar-ct"] .navbar-nav .active .nav-link:not(.btn),
[class*="navbar-ct"] .navbar-nav .active .nav-link:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav .active .nav-link:focus:not(.btn),
[class*="navbar-ct"] .navbar-nav .nav-item .nav-link:not(.btn),
[class*="navbar-ct"] .navbar-nav .nav-item .nav-link:hover:not(.btn),
[class*="navbar-ct"] .navbar-nav .nav-item .nav-link:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav .nav .nav-item .nav-link.btn:hover, [class*="navbar-ct"] .navbar-nav .nav .nav-item .nav-link.btn:hover {
  background-color: transparent; }

.navbar-transparent .navbar-nav .show .nav-link,
.navbar-transparent .navbar-nav .show .nav-link:hover,
.navbar-transparent .navbar-nav .show .nav-link:focus, [class*="navbar-ct"] .navbar-nav .show .nav-link,
[class*="navbar-ct"] .navbar-nav .show .nav-link:hover,
[class*="navbar-ct"] .navbar-nav .show .nav-link:focus {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .btn-default, [class*="navbar-ct"] .btn-default {
  color: #FFFFFF;
  border-color: #FFFFFF; }

.navbar-transparent .btn-default.btn-fill, [class*="navbar-ct"] .btn-default.btn-fill {
  color: #9A9A9A;
  background-color: #FFFFFF;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .show .dropdown-toggle.btn-fill.btn-default, [class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .show .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2); }

.navbar-default {
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .navbar-default .navbar-nav .nav-item .nav-link:not(.btn) {
    color: #9A9A9A; }
  .navbar-default .navbar-nav .active .nav-link,
  .navbar-default .navbar-nav .active .nav-link:not(.btn):hover,
  .navbar-default .navbar-nav .active .nav-link:not(.btn):focus,
  .navbar-default .navbar-nav .nav-item .nav-link:not(.btn):hover,
  .navbar-default .navbar-nav .nav-item .nav-link:not(.btn):focus {
    background-color: transparent;
    border-radius: 3px;
    color: #1DC7EA;
    opacity: 1;
    filter: alpha(opacity=100); }
  .navbar-default .navbar-nav .show .nav-link,
  .navbar-default .navbar-nav .show .nav-link:hover,
  .navbar-default .navbar-nav .show .nav-link:focus {
    background-color: transparent;
    color: #1DC7EA; }
  .navbar-default .navbar-nav .navbar-toggle:hover, .navbar-default .navbar-nav .navbar-toggle:focus {
    background-color: transparent; }
  .navbar-default:not(.navbar-transparent) .btn-default:hover {
    color: #1DC7EA;
    border-color: #1DC7EA; }
  .navbar-default:not(.navbar-transparent) .btn-neutral,
  .navbar-default:not(.navbar-transparent) .btn-neutral:hover,
  .navbar-default:not(.navbar-transparent) .btn-neutral:active {
    color: #9A9A9A; }

/*      Navbar with icons            */
.navbar-icons.navbar .navbar-brand {
  margin-top: 12px;
  margin-bottom: 12px; }

.navbar-icons .navbar-nav .nav-item .nav-link {
  text-align: center;
  padding: 6px 15px;
  margin: 6px 3px; }

.navbar-icons .navbar-nav [class^="pe"] {
  font-size: 30px;
  position: relative; }

.navbar-icons .navbar-nav p {
  margin: 3px 0 0; }

.navbar-form {
  -webkit-box-shadow: none;
  box-shadow: none; }
  .navbar-form .form-control {
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
    height: 22px;
    font-size: 16px;
    line-height: 1.5;
    color: #E3E3E3; }
  .navbar-transparent .navbar-form .form-control,
  [class*="navbar-ct"] .navbar-form .form-control {
    color: #FFFFFF;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6); }

.navbar-ct-blue {
  background-color: #4091e2; }

.navbar-ct-azure {
  background-color: #63d8f1; }

.navbar-ct-green {
  background-color: #a1e82c; }

.navbar-ct-orange {
  background-color: #ffbc67; }

.navbar-ct-red {
  background-color: #fc727a; }

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent; }

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0; }
  .navbar-toggle .icon-bar {
    background-color: #FFFFFF; }
  .navbar-toggle .navbar-collapse,
  .navbar-toggle .navbar-form {
    border-color: transparent; }
  .navbar-toggle.navbar-default .navbar-toggle:hover,
  .navbar-toggle.navbar-default .navbar-toggle:focus {
    background-color: transparent; }


