.widget-area .widget:last-child {
    margin-bottom: 0;
}
.widget-area .widget .widget-title {
    padding-bottom: 18px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
     /* font-family: "Montserrat",sans-serif;*/
    line-height: 1.2;
    margin: 0 0 20px;
    margin-bottom: 20px;
    color: #f78119;
    font-size: 20px;
    font-weight: 700;
}
.widget .recent-news {
    padding-top: 5px;
}
.widget ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.widget .recent-news li:not(:last-child) {
    margin-bottom: 25px;
}
.widget ul li {
    position: relative;
}
.widget .recent-news .thumb {
    float: left;
    width: 70px;
    margin-right: 20px;
}
.widget .recent-news h6 {
    margin: -3px 0 3px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
   /*  font-family: "Montserrat",sans-serif;*/
    color: #1b1d21;
}
.widget .recent-news h6 a {
    color: #1b1d21;
    display: block;
}
.widget .recent-news .entry-date {
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #b5b5b5;
}