
/*-- news --*/
.inner-page{
    background:white;
}
.news-left-img {
    /*background: url(../images/s4.jpg) no-repeat 0px 0px;
    background-size: cover;*/
    /*min-height: 200px;
    width:auto;*/
}

.news-left-text h5 {
    color: #FFFFFF;
    font-size: 22px;
    letter-spacing: 1px;
}

.news-left-text {
    background: rgba(0, 0, 0, 0.48);
    padding: 10px 0;
    margin: 0 5em;
    text-align: center;
}

.color-event1 {
    background: #FC9928;
}

.color-event2 {
    background: #2da0ff;
}

.color-event3 {
    background: #E91E63;
}

.color-event4 {
    background: #12e8b4;
}

.text-color1 a {
    /*color: #FC9928;*/
    color: #4384c4;
}

.text-color2 a {
    /*color: #398ab9;*/
    color: #333333;
    font-size: 14px;
    text-align: justify;
    margin: 0px;
    padding: 0px;
}

.text-color3 a {
    color: #E91E63;
}

.text-color4 a {
    color: #12e8b4;
}

.date-grid {
    margin: 10px 0 15px;
}

.admin a {
    color: #212121;
    font-size: .9em;
    margin: 0;
    text-decoration: none;
}

.time {
    margin-top: 6px;
}

    .time p {
        color: #383838;
        font-size: .875em;
        margin: 0;
    }

.listnews_left {
    clear: both;
    display: block;
    float: left;
    width: 15%;
}

.listnews_right {
    display: block;
    float: right;
    width: 85%;
}

.news-left-top-text a {
    text-decoration: none;
    /*color: #398ab9;*/
    color: #fa7d37;
}

    .news-left-top-text a:hover {
        color: #0b38b9;
    }

.lastnews-left-top-text a {
    text-decoration: none;
    text-align: justify;
   /* font-family: 'UEH', serif !important; */
}

    .lastnews-left-top-text a:hover {
        color: #c80f34;
    }

.xem_them {
    text-decoration: underline;
    font-style: italic;
}

    .xem_them:hover {
        color: #6a98c6;
    }

.news-grid-info-bottom-w3ls-text p {
    font-size: .9em;
}

.news-left-img1 {
  /*  background: url(../images/s1.jpg) no-repeat 0px 0px !important;*/
    background-size: cover !important;
}

.yaallahaa-news-grid:nth-child(2) {
    margin: 2em 0 0 0;
}

.news-left-img2 {
    /*  background: url(../images/s2.jpg) no-repeat 0px 0px !important;*/
    background-size: cover !important;
}

.news-left-img3 {
   /* background: url(../images/s3.jpg) no-repeat 0px 0px !important;*/
    background-size: cover !important;
}

.news-left-top-text a:focus {
    outline: none;
}

.content_news_list {
    margin-bottom: 1em;
    border-bottom: 1px solid #cacaca;
    padding-bottom: 15px;
}

.news_body {
    min-height: 600px;
    padding-right: 20px;
}
.news_body img {
    max-width:98%;
    height:auto;
}
    /*-- model --*/
    .model-img-info img {
        width: 100%;
    }

.modal-header h4 {
    font-size: 2em;
    color: #212121;
    text-decoration: none;
    letter-spacing: 2px;
    padding: 0;
}


.modal-body p {
    margin: 1em 0 0 0;
}


/*-- //model --*/


/*-- //news --*/



/*BOX_NEWS*/
.box_news {
    /*margin-bottom: 10px;
    margin-top: 10px;*/
    padding: 10px 0px 10px 0px;
    width: 100%;
    height: auto;
    display: block;
    /*background-color: #005180;*/
    background-color: white;
}

.news_group_box {
    width: 100%;
    min-height: 100px;
    /*background-color: #005180;*/
    text-align: center;
    font-size: 2.25em;
    line-height: 1.16667;
    /*padding: 0 0 .38889em 0;*/
    color: black;
    /*color: #f46902;*/
    padding: 10px 6px 30px 6px;
}

    .news_group_box h1 {
        font-size: 1.3em;
        font-weight: 900;
    }

.news_box_list {
    background-color: white;
}

.news_box_item {
    background-color: white;
    min-height: 460px;
}

.news_box_img {
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px;
    /*border-bottom: 4px solid #55acee;*/
}

.news_box_title {
    background-color: white;
    padding: 0px 6px 0px 6px;
    /*font-size: 14pt;*/
}

    .news_box_title a {
        color: #398ab9;
        text-decoration: none;
    }

        .news_box_title a:hover {
            /*text-decoration:underline;*/
            color: #0b38b9;
        }

.news_box_time {
    font-size: .9em;
    padding: 6px 6px 4px 0px;
    color: #3f3c30 !important;
    /*color: #79797e !important;*/
    font-weight: 600;
}

    .news_box_time h4 {
    }

.news_box_note {
    background-color: white;
    padding: 10px 6px 10px 6px;
    color: #414141;
    font-size: 13pt;
}

.news_box_footer {
    height: 50px;
    width: 100%;
    background-color: #005180;
    display: block;
}

.lastnew_title {
    padding: 5px 0px 6px 0px !important;
    border-bottom: 1px solid #cacaca;
    color: #000 !important;
    font-size: 1.6em;
    font-weight: 700;
   /* font-family: 'UEH', serif !important; */
}

.bng_header {
    width: 100%;
}

.bng_body {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 20px;
}

.bng_top {
    background-color: #f44336;
    padding: 5px;
    display: block;
    width: 200px;
    height: 30px;
    float: left;
    color: white;
}

.bng_top_ganday {
    clear: both;
    background-color: #f44336;
    padding: 5px;
    display: block;
    width: 100px;
    height: 30px;
    float: left;
    color: white;
}

.bng_line {
    background-color: #f44336;
    width: 100%;
    height: 2px;
    float: left;
    margin-bottom: 20px;
}

.bng_item {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    float: left;
}

.bng_title {
    background-color: white;
    padding: 0px 6px 0px 6px;
    color: #79797e !important;
    /*font-size: 14pt;*/
}

    .bng_title a {
        color: #398ab9;
        text-decoration: none;
    }

        .bng_title a:hover {
            /*text-decoration:underline;*/
            color: #0b38b9;
        }

.bng_time {
    padding: 0px 6px 0px 6px;
    /*color: #005180 !important;*/
    color: #79797e !important;
    font-weight: normal;
}

    .bng_time h4 {
    }

.hnews_top {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
}
.hnews_top_sologan {
    max-width: 30%;
    padding: 10px;
    right: 20px;
    bottom: 20px !important;
    top: auto !important;
    box-shadow: none;
    background: rgba(100, 100, 100, 0.6);
    position: absolute;
}
.hnews_top_sologan_title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    margin-bottom: .512em;
}
.hnews_top_sologan a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}
    .hnews_body {
    }

.hnews_item {
    width: 100%;
    display: block;
    padding: 10px;
}

.hnews_artice {
    /*width: calc(25% - 43.75px);*/
    float: left;
    width: 100%;
    /*margin-left: 35px;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, .15), 0 3px 3px rgba(0, 0, 0, .15);
    /*padding: 10px;*/
}

.hnews_figure, .hnews_img, .hnews_picture {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: none;
    margin: 0;
    transition: transform .2s ease;
}

.hnews_picture {
    overflow: hidden;
}

.hnews_figure {
    min-height: 200px;
}

.hnews_img:hover {
    transform: scale(1.1);
}

.hnews_content {
    padding: 14px;
    color: #000 !important;
    min-height: 140px;
}

    .hnews_content a {
        color: #000 !important;
        text-align: justify !important;
        font-weight: 700;
        line-height:1.25em !important;
    }

        .hnews_content a:hover {
            color: #006cb8 !important;
        }

.hnews_footer {
    clear: both;
    float: left;
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 30px;
    color: white !important;
    font-weight: bold;
    font-size: 13pt;
}

.aos-animate {
    transition-delay: 50ms;
    opacity: 1;
    transform: translateZ(0) scale(1);
}

.register-form-main2 {
    margin: auto;
    width: 75%;
    margin-top: 100px;
    display: block;
    height: 100%;
}

.login_line {
    height: 150px;
    background-color: Red;
    width: 1px;
    margin: 30px 0px 0px 0px;
}

.login_logo {
    margin-top: 20%;
}

.login_notice {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 10px;
    border-top: 2px dotted gray;
    color: white;
}

footer h4 {
    /*color:#f56311;*/
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
}

footer li {
    text-align: left;
    padding: 3px 0px 3px 0px;
    margin: 10px 0px 10px 0px;
    
}

footer p {
    text-align: left;
    /*font-size: 0.95em;*/
}

footer a {
    color: #f1f1f1 !important;
  /*  font-family: 'UEH', serif !important; */
}

    footer a:hover {
        text-decoration: underline;
        color: white;
    }

footer .logo {
    text-align: left;
}
/*END BOX_NEWS*/

/*-- about --*/

h3.tittle {
    color: #000;
    font-size: 3em;
    text-align: center;
    margin-bottom: 16px;
}

    h3.tittle span {
        color: #f46902;
    }

.tittle-style {
    position: relative;
}

    .tittle-style:before {
        content: "";
        /*background: #000;*/
        background: white;
        width: 7%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 46%;
    }

    .tittle-style:after {
        content: "";
        background: #f46902;
        width: 3%;
        height: 5px;
        position: absolute;
        top: -2px;
        left: 48%;
        border-radius: 6px;
    }

.title-div {
    margin-bottom: 70px;
}

.banner-bottom-w3l,
.contact {
    padding: 5em 0 0;
}

.banner_bottom_left h4 {
    font-size: 2em;
    color: #262c38;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 600;
}

.banner_bottom_left {
    padding-right: 4em;
}

    .banner_bottom_left p {
        color: #666;
        line-height: 2em;
        margin-bottom: 1em;
    }

        .banner_bottom_left p:nth-child(3) {
            margin-bottom: 0;
            border-top: 2px solid #f46902;
            padding-top: 13px;
        }


/*-- //about --*/

/*-- contact --*/

.contact-grid {
    padding: 3em 0 5em;
    width: 60%;
}

.contact-text1 {
    padding-right: 3em;
}

    .contact-text1 h4 {
        color: #000;
        font-size: 30px;
        margin-bottom: 18px;
        letter-spacing: 1px;
    }

        .contact-text1 h4 span {
            color: #005180;
        }

    .contact-text1 p {
        border-bottom: 2px solid #f46902;
        padding-bottom: 25px;
    }

.contact-w3lsright iframe {
    width: 93%;
    min-height: 550px;
    border: none;
}

.contact-lsleft {
    background: url(bg7.jpg) no-repeat center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    margin-top: -16em;
    /*margin-bottom: 5em;*/
}

.address-grid {
    margin-top: 4em;
}

    .address-grid ul {
        padding: 0;
        margin: 2em 0 0 0;
    }

        .address-grid ul li {
            display: block;
            color: #fff;
            font-size: 16px;
            margin-bottom: 24px;
            letter-spacing: 1px;
        }

.w3_address span {
    margin-right: 1em;
    font-size: 18px;
    color: #fff;
}

.address-grid ul li a {
    color: #fff;
    text-decoration: none;
}

.contact-grid h4,
.address-grid h4 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 1em;
    letter-spacing: 1px;
    font-weight: 600;
}

.contact-lsleft input[type="text"],
.contact-lsleft input[type="email"],
.contact-lsleft textarea {
    width: 100%;
    color: #fff;
    background: transparent;
    outline: none;
    letter-spacing: 1px;
    font-size: 15px;
    padding: 12px;
    margin-bottom: 23px;
    border: solid 1px #fff;
    -webkit-appearance: none;
    -webkit-box-shadow: 0 0 18px 0px rgba(54, 55, 55, 0.54);
    -moz-box-shadow: 0 0 18px 0px rgba(54, 55, 55, 0.54);
    box-shadow: 0 0 7px 0px rgba(252, 252, 252, 0.54);
}

.contact-lsleft textarea {
    resize: none;
    min-height: 13.5em;
}

.contact-lsleft input[type="submit"] {
    outline: none;
    color: #000;
    padding: 10px 30px;
    font-size: 16px;
    letter-spacing: 1px;
    background: #fff;
    border: solid 1px #fff;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -o-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
}

    .contact-lsleft input[type="submit"]:hover {
        background: none;
        color: #fff;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
    }


/*--placeholder-color--*/

.contact-lsleft ::-webkit-input-placeholder {
    color: #d8d8d8;
}

.contact-lsleft :-moz-placeholder {
    /* Firefox 18- */
    color: #d8d8d8;
}

.contact-lsleft ::-moz-placeholder {
    /* Firefox 19+ */
    color: #d8d8d8;
}

.contact-lsleft :-ms-input-placeholder {
    color: #d8d8d8;
}


/*--//placeholder-color--*/


/*-- //contact --*/
